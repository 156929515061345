<template>
  <ftx-dialog
    :dialog="dialog"
    position="standard"
    :maximized="false"
    :persistent="true"
  >
    <template v-slot:DialogContent>
      <q-card class="simpleDialog q-pa-none">
        <q-card-section
          :class="isMobile ? 'q-px-sm q-pt-xl q-pb-md' : 'q-pa-xl'"
        >
          <!-- <q-btn
            icon="close"
            flat
            round
            dense
            v-close-popup
            class="absolute-top-right q-mt-md q-mr-md"
          /> -->
          <div class="container">
            <div class="row q-col-gutter-md justify-center">
              <div class="col-12 text-center">
                <q-icon size="3rem" name="warning" color="red" />
              </div>
              <div class="col-12 text-center">
                <div class="text-body1">
                  Unfortunately, your age verification process is failed! Click
                  "Retry" to verify your age or "Continue" to access the
                  products that don't require age verification. You can verify
                  your age by choosing "Verify My Age" from your profile
                  anytime.
                </div>
                <div class="row q-col-gutter-md q-mt-lg">
                  <div class="col-12 col-md-6">
                    <div class="form-action pb-0">
                      <q-btn
                        unelevated
                        no-caps
                        color="tertiary"
                        label="Retry"
                        class="q-btn-lg full-width"
                        @click="triggerEAIVFlow"
                      />
                    </div>
                  </div>
                  <div class="col-12 col-md-6">
                    <div class="form-action pb-0">
                      <q-btn
                        unelevated
                        no-caps
                        color="secondary"
                        label="Continue"
                        class="q-btn-lg full-width"
                        v-close-popup
                        @click="
                          $store.commit(
                            'ageVerification/SET_SHOW_VERIFICATION_POPUP',
                            false
                          )
                        "
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </q-card-section>
        <!-- <q-card-actions class="bg-grey-2">
          <div class="copyright full-width text-center">
            Powered by
            <q-img src="images/ftx-identity.svg" width="60px" height="30px" />
          </div>
        </q-card-actions> -->
      </q-card>
    </template>
  </ftx-dialog>
</template>

<script>
import { mapGetters } from 'vuex'
import { hideShowFullSpinner } from 'src/utils'
import IdentityMixin from 'src/utils/IdentityMixin'

export default {
  name: 'VerificationFailDialog',
  mixins: [IdentityMixin],
  data() {
    return {
      dialog: 'VerificationFailDialog',
    }
  },
  computed: {
    ...mapGetters('customer', ['customer']),
    ...mapGetters('address', ['getAddressByCustomerAddressID']),

    dialogProperties() {
      return this.getDialogProperties(this.dialog)
    },
    getEAIVExternalURL() {
      return process.env.EAIV_ExternalURL
    },
    getRedirectUri() {
      let uri = ''
      if (this.isCordova) {
        uri = process.env.ANDROID_DEEP_LINK
      } else {
        uri = Object.keys(this.$route.query).length
          ? window.location.href
          : window.location.origin + window.location.pathname
      }
      return uri
    },
  },
  mounted() {
    this.setHeaderHeight()
  },
  methods: {
    retryAgeVerification() {
      if (
        ['Checkout', 'OrderEdit'].includes(this.dialogProperties?.routeName) &&
        this.customer.isVerifiedOnline
      ) {
        this.triggerEAIVFlow()
      } else {
        this.showPersonalInfoDialog()
      }
    },
    triggerEAIVFlow() {
      hideShowFullSpinner(true)
      let reqData = {
        collectPersonalInfo: true,
        redirectUri: this.getRedirectUri,
        verificationDuring: 0,
        customerID: null,
        ...this.dialogProperties,
      }

      if (this.customer.isVerifiedOnline) {
        reqData.collectPersonalInfo = false
        reqData.personalInfo = this.personalInfoObj()
      } else if (this.isLoggedIn) {
        reqData.preFillInformation = this.personalInfoObj()
      }

      this.$store
        .dispatch('ageVerification/sendPersonalInfo', reqData)
        .then((response) => {
          if (response.success && response.data && response.data?.sessionId) {
            if (this.isCordova) {
              this.openURL(
                this.getEAIVExternalURL +
                  `?sessionId=${response.data.sessionId}`
              )
            } else if (!this.isCordova && 'FTXIdentityLite' in window) {
              let payloadObj = {
                sessionId: response.data.sessionId,
                onSuccess: this.identitySuccessHandler,
                onError: this.identityErrorHandler,
                styleOptions: {
                  height: `calc(100dvh - ${this.getHeaderHeight}px)`,
                  top: `${this.getHeaderHeight}px`,
                },
              }

              this.changeDialogState({
                dialog: 'VerificationFailDialog',
                val: false,
              })
              this.initEAIVPopup(payloadObj)
            }
          } else if (!response.success && response.message) {
            this.showError(response.message)
          }
        })
        .finally(() => {
          hideShowFullSpinner(false)
        })
    },
    showPersonalInfoDialog() {
      let address = null,
        dob = '',
        personalInfo = {},
        properties = {}
      if (this.isLoggedIn) {
        address = this.getAddressByCustomerAddressID(
          this.customer.defaultAddressID
        )

        dob = this.currentUser.dob
          ? this.$options.filters.formatDate(this.currentUser.dob, 'YYYY-MM-DD')
          : ''
        personalInfo = {
          firstName: this.currentUser.firstName || '',
          lastName: this.currentUser.lastName || '',
          dob: dob,
          addressLine1: address?.line1 || '',
          addressLine2: address?.line2 || '',
          city: address?.city || '',
          state: address?.state || '',
          zip: address?.zip || '',
          phone: this.currentUser.phoneNumber || '',
          email: this.currentUser.email || '',
          countryCode: this.currentUser.countryCode || '+1',
        }
        properties = { personalInfo }
      }

      if (!this.isCordova) {
        properties.onSuccessCallback = this.identitySuccessHandler
        properties.onErrorCallback = this.identityErrorHandler
      }
      this.changeDialogState({
        dialog: 'PersonalInfoDialog',
        parentDialog: 'VerificationFailDialog',
        val: true,
        properties,
      })
    },
    openURL(url) {
      if (this.isCordova) {
        window.open(url, '_system')
      } else {
        window.location = url
      }
    },
    personalInfoObj() {
      let address = null,
        dob = '',
        personalInfo = {}
      if (this.isLoggedIn) {
        address = this.getAddressByCustomerAddressID(
          this.customer.defaultAddressID
        )

        dob = this.currentUser.dob
          ? this.$options.filters.formatDate(this.currentUser.dob, 'YYYY-MM-DD')
          : ''
        personalInfo = {
          firstName: this.currentUser.firstName || '',
          lastName: this.currentUser.lastName || '',
          dob: dob,
          addressLine1: address?.line1 || '',
          addressLine2: address?.line2 || '',
          city: address?.city || '',
          state: address?.state || '',
          zip: address?.zip || '',
          phone: this.currentUser.phoneNumber || '',
          email: this.currentUser.email || '',
          countryCode: this.currentUser.countryCode || '+1',
        }
      }
      return personalInfo
    },
  },
}
</script>

<style lang="scss"></style>
